
@font-face {
  font-family: 'PoppinsRegular';
  src: url('./assets/static/fonts/poppins/Poppins-Regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('./assets/static/fonts/poppins/Poppins-Bold.ttf');
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'MainBasketRun';
  src: url('./assets/static/fonts/TitanOne-Regular.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FagoCol';
  src: url('./assets/static/fonts/FagoCoLf-Bold.ttf');
  /* font-weight: bold; */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FagoCoLfBlack';
  src: url('./assets/static/fonts/FagoCoLf-Black.ttf');
  font-style: normal;
  font-display: swap;
}

body {
    padding: 0;
    margin: 0;

  }

canvas {
    /* width: 100%; */
      background-color: #E63C8C;
      position: absolute;
      /*top: 0;*/
      /*left: 50%;*/
      /*transform: translate(-50%, 0);*/
      width: 100%;
	    height: 100%;
      background: linear-gradient(#E63C8C, #d3327d);
}

#fontsCont {
    font-family: 'MainBasketRun';
    font-size: 2pt;
    font-weight: bold;
    position: absolute;
}

#reportMessages {
  padding: 5px;
  color: gray;
  z-index:10;
  position: absolute;
  font-size: 12pt;
  left: 10px;
  top:100px;
  width: 120px;
  opacity: 0.63;
  display:block;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background: linear-gradient(#E63C8C, #d3327d);
  background-image: url('./assets/dom/images/preloader-bg.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh; /* Ensures it covers the viewport height */
  margin: 0;     /* Removes default margin */
  overflow: hidden; /* Prevents scrolling */
  align-items: center;
  z-index: 10;
}

#preloader .preloader-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;  
  left: 0;
  right: 0;
  bottom:6%;
  margin: auto;
  min-height: 40%;
}

#preloader .preloader-image {
  align-self: flex-start; 
  width: 100%;
  height: 400px;
}
#preloader .preloader-logo-cont{
  /* background-color: #C8B686; */
  background-image: url( "./assets/dom/images/logo.png");
  background-size: auto 100%; 
  background-position: center center;
  background-repeat: no-repeat;  
  width: 100%;
  height: 100%;

}

#preloader .progress {
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  max-height: 100px;
  font-family: PoppinsRegular;
}

#preloader .progress p{
  padding-top: 15px;
  display: inline-block; 
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
}

#preloader .loader {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
}

#preloader .loader-quart {
	border-radius: 50px;
	border: 6px solid rgba(255, 255, 255, 0.3);

}

#preloader .loader-quart:after {
	content: '';
	position: absolute;
	top: -6px; 
	left: -6px;
	bottom: -6px;
	right: -6px;
	border-radius: 50px;
	border: 6px solid transparent;
	border-top-color: #fff;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
}

@keyframes spin {
0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}


.tutorial-overlay{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}

.tutorial {
  display: flex;
  font-family: PoppinsRegular;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  
  max-width: 353px;
  margin: auto;
  padding: 0px;
  color: #453E3E;

  border-radius: 21.633px;
  border: 3.605px solid #C8B686;
  background: #FAEFD2;
  box-shadow: 1.803px 3.605px 0px 0px #8F7F53;
}

.tutorial-content {
  font-family: PoppinsRegular;
  flex-grow: 1;
  margin-top: 77px;
  overflow-y: auto;
  /* background: #292929; */
  /* padding: 10px; */
  /* border-radius: 0 0 32px 32px; */



}



.tutorial-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background:none;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  z-index: 3;
}

.tutorial-header h2 {
  font-family: MainBasketRun;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 12px 0 12px 30px;

  font-size: 36px;
  font-weight: 900;
  line-height: 60px;

}

.tutorial-header .close-btn{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 32px;
  cursor: pointer;
}

.tutorial-header .close-btn img{
  height: 20px;
  width: 18px;
  margin: 10px;
  padding: 10px;
}


.tutorial-content .inner-content{
  margin: 6px;
}

.tutorial-content .block {
  margin-bottom: 23px;
}

.tutorial-content h4 {
  padding-top: 2px;
  font-family: 'MainBasketRun';
  font-size: 20px;
  margin: 4px 0 8px 6px;
}

.tutorial-content h6 {
  font-family: 'PoppinsBold';
  font-size: 16px;
  font-weight: 900;
}

.tutorial-content p {
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  margin-left: 6px;
}

.tutorial-content .product-line {
  padding-top: 8px;
  padding-bottom: 0px;
  margin-bottom: 0;  
}

.tutorial  .block .items {
  margin-top: -10px;  
}


.product-line {
  margin-left: 0px;
  margin-right: 0px;

}

.tutorial  .block .top-line {
  margin-left: 4px;
  /* text-align: center; */
  justify-content: space-between;
  display: flex;
}

#tutorial1en  .block .top-line, #tutorial1zh  .block .top-line{
  padding-left: 8px;;
  padding-right: 8px;;
  justify-content: space-between;
  display: flex;

  margin-left: 4px;
  justify-content: space-between;
  display: flex;
  align-items: center; 
  gap: 10px; 
}



#tutorial1en  .top-line img{
  /* padding-right: 10px; */
}




.cart-control {
  display: flex;
  height: 200px;
  margin-bottom: 20px;
  background:  url('./assets/cart.png') center no-repeat;
}

.cart-control img {
  display: inline-block;
  text-align: center;
  margin: auto;
  padding-top: 90px;
}

.cart-control  img:nth-of-type(2) {
  padding-top: 110px;
}


h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}
