@font-face {
  font-family: PoppinsRegular;
  src: url("Poppins-Regular.49aef68e.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PoppinsBold;
  src: url("Poppins-Bold.48c34fc6.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MainBasketRun;
  src: url("TitanOne-Regular.2147ecec.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FagoCol;
  src: url("FagoCoLf-Bold.488982d3.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FagoCoLfBlack;
  src: url("FagoCoLf-Black.3c1b1f7c.ttf");
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
}

canvas {
  background: linear-gradient(#e63c8c, #d3327d);
  width: 100%;
  height: 100%;
  position: absolute;
}

#fontsCont {
  font-family: MainBasketRun;
  font-size: 2pt;
  font-weight: bold;
  position: absolute;
}

#reportMessages {
  color: gray;
  z-index: 10;
  opacity: .63;
  width: 120px;
  padding: 5px;
  font-size: 12pt;
  display: block;
  position: absolute;
  top: 100px;
  left: 10px;
}

#preloader {
  text-align: center;
  z-index: 10;
  background: url("preloader-bg.3bac77bf.jpg") top / cover no-repeat;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#preloader .preloader-container {
  flex-direction: column;
  min-height: 40%;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 10% 0 6%;
}

#preloader .preloader-image {
  align-self: flex-start;
  width: 100%;
  height: 400px;
}

#preloader .preloader-logo-cont {
  background-image: url("logo.229a86e3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
}

#preloader .progress {
  align-self: flex-end;
  width: 100%;
  max-height: 100px;
  margin-top: auto;
  font-family: PoppinsRegular;
}

#preloader .progress p {
  color: #fff;
  text-align: center;
  margin-left: 10px;
  padding-top: 15px;
  font-size: 14px;
  display: inline-block;
}

#preloader .loader {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

#preloader .loader-quart {
  border: 6px solid #ffffff4d;
  border-radius: 50px;
}

#preloader .loader-quart:after {
  content: "";
  border: 6px solid #0000;
  border-top-color: #fff;
  border-radius: 50px;
  animation: 1s linear infinite spin;
  position: absolute;
  inset: -6px;
}

@-webkit-keyframes spin {
  0% {
    tranform: rotate(0deg);
    -webkit-transform: rotate(0);
  }

  100% {
    tranform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tutorial-overlay {
  z-index: 2;
  background-color: #000c;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.tutorial {
  color: #453e3e;
  background: #faefd2;
  border: 3.605px solid #c8b686;
  border-radius: 21.633px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 353px;
  height: 400px;
  margin: auto;
  padding: 0;
  font-family: PoppinsRegular;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1.803px 3.605px #8f7f53;
}

.tutorial-content {
  flex-grow: 1;
  margin-top: 77px;
  font-family: PoppinsRegular;
  overflow-y: auto;
}

.tutorial-header {
  text-align: left;
  text-transform: uppercase;
  z-index: 3;
  background: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-header h2 {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  width: calc(100% - 20px);
  margin: 12px 0 12px 30px;
  font-family: MainBasketRun;
  font-size: 36px;
  font-weight: 900;
  line-height: 60px;
}

.tutorial-header .close-btn {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
}

.tutorial-header .close-btn img {
  width: 18px;
  height: 20px;
  margin: 10px;
  padding: 10px;
}

.tutorial-content .inner-content {
  margin: 6px;
}

.tutorial-content .block {
  margin-bottom: 23px;
}

.tutorial-content h4 {
  margin: 4px 0 8px 6px;
  padding-top: 2px;
  font-family: MainBasketRun;
  font-size: 20px;
}

.tutorial-content h6 {
  font-family: PoppinsBold;
  font-size: 16px;
  font-weight: 900;
}

.tutorial-content p {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
}

.tutorial-content .product-line {
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 0;
}

.tutorial .block .items {
  margin-top: -10px;
}

.product-line {
  margin-left: 0;
  margin-right: 0;
}

.tutorial .block .top-line {
  justify-content: space-between;
  margin-left: 4px;
  display: flex;
}

#tutorial1en .block .top-line, #tutorial1zh .block .top-line {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-left: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.cart-control {
  background: url("cart.d6adc45c.png") center no-repeat;
  height: 200px;
  margin-bottom: 20px;
  display: flex;
}

.cart-control img {
  text-align: center;
  margin: auto;
  padding-top: 90px;
  display: inline-block;
}

.cart-control img:nth-of-type(2) {
  padding-top: 110px;
}

h6 {
  margin: 0 0 5px;
}

p {
  margin: 0;
}

/*# sourceMappingURL=index.92353832.css.map */
